import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import localeES from '@angular/common/locales/es-CL';
import localeESExtra from '@angular/common/locales/extra/es-CL';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { PersonalService } from './shared/services/personal.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ContratosService } from './shared/services/contratos.service';
import { AccidentesService } from './shared/services/accidentes.service';
import { CargosService } from './shared/services/cargos.service';
import { ConveniosService } from './shared/services/convenios.service';
import { EmbarquesService } from './shared/services/embarques.service';
import { CondicionesService } from './shared/services/condiciones.service';
import { NavesService } from './shared/services/naves.service';
import { GuardiasService } from './shared/services/guardias.service';
import { ReemplazosService } from './shared/services/reemplazos.service';
import { TgmsService } from './shared/services/tgms.service';
import { registerLocaleData } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material';
import { EspeciesService } from './shared/services/especies.service';
import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { ErrorInterceptor } from './shared/helpers/error.interceptor';

registerLocaleData(localeES, 'es', localeESExtra);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    OverlayModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AccidentesService,
    CargosService,
    ContratosService,
    ConveniosService,
    EmbarquesService,
    CondicionesService,
    NavesService,
    PersonalService,
    GuardiasService,
    ReemplazosService,
    TgmsService,
    EspeciesService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
