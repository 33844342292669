import { Injectable } from '@angular/core';
import { Cargo } from '../models/cargo';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CargosService {

  constructor(private http: HttpClient) { }

  public getAll(): Observable<Cargo[]> {
    return this.http.get<Cargo[]>(`${environment.apiServer}/cargos`);
  }

  public get(id: number): Observable<Cargo> {
    return this.http.get<Cargo>(`${environment.apiServer}/cargos/${id}`)
      .pipe(
        map(c => Cargo.create(c))
      );
  }

  public create(cargo: Cargo): Observable<Cargo> {
    const data: any = cargo;

    return this.http.post<Cargo>(`${environment.apiServer}/cargos`, data)
      .pipe(map(c => Cargo.create(c)));
  }

  public edit(cargo: Cargo): Observable<Cargo> {
    const data: any = cargo;

    return this.http.post<Cargo>(`${environment.apiServer}/cargos/${cargo.id}`, data)
      .pipe(
        map(c => Cargo.create(c))
      );
  }

  public delete(cargo: Cargo): Observable<Cargo> {
    return this.http.delete<Cargo>(`${environment.apiServer}/cargos/${cargo.id}`);
  }


}
