import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, tap, filter, take, switchMap, map } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(
        catchError(err => {
          switch (err.status) {
            case 400:
              return this.handle400Error(err);
            case 401:
              return this.handle401Error(request, next);
          }

          const error = err.error.message || err.statusText;
          return throwError(error);
        })
      );
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
      if (!this.isRefreshingToken) {
          this.isRefreshingToken = true;
          this.tokenSubject.next(null);

          return this.authenticationService.refreshToken()
            .pipe(
              catchError(err => {
                this.isRefreshingToken = false;
                this.authenticationService.logout();
                location.reload();

                return throwError(err);
              }),
              switchMap((res: any) => {
                this.isRefreshingToken = false;
                if (res && res.token) {
                  this.tokenSubject.next(res.token);
                  return next.handle(this.addToken(req, res.token));
                } else {
                  // If we don't get a new token, we are in trouble so logout.
                  this.authenticationService.logout();
                  location.reload();
                }
              }),
            );
      } else {
          return this.tokenSubject
            .pipe(
              filter(token => token != null),
              take(1),
              switchMap(token => {
                return next.handle(this.addToken(req, token));
              })
            );
      }
  }

  handle400Error(error) {
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
        // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
        this.authenticationService.logout();
        // location.reload();
    }

    return throwError(error);
  }

  addToken(req: HttpRequest<any>, token: string) {
      return req.clone({ setHeaders: { Authorization: 'Bearer ' + token }});
  }
}
