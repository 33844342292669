export class ConditionError {
  public tipo: string;
  public persona: string;
  public embarque: string;
  public condicion: string;
  public guardia: string;
  public reemplazo: string;
  public descanso: boolean;

  public static create(data): ConditionError {
    const conditionError =  Object.assign(new ConditionError, data);

    return conditionError;
  }

  public toString() {
    switch (this.tipo) {
      case 'embarque':
        return `Embarque(${this.embarque}): ${this.persona}`;
      case 'condicion':
        return `${this.condicion}: ${this.persona}`;
      case 'guardia':
        let tipoGuardia = 'Guardia ';
        if (this.descanso) {
          tipoGuardia = '';
        }
        return `${tipoGuardia}${this.guardia}: ${this.persona}`;
      case 'reemplazo':
        return `${this.reemplazo}: ${this.persona}`;
      default:
        return 'Error';
    }
  }
}
