import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Reemplazo } from '../models/reemplazo';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReemplazosService {
  constructor(private http: HttpClient) {}

  public getAll(): Observable<Reemplazo[]> {
    return this.http
      .get<Reemplazo[]>(`${environment.apiServer}/reemplazos`)
      .pipe(map(res => res.map(r => Reemplazo.create(r))));
  }

  public get(id: number): Observable<Reemplazo> {
    return this.http
      .get<Reemplazo>(`${environment.apiServer}/reemplazos/${id}`)
      .pipe(map(r => Reemplazo.create(r)));
  }

  public create(reemplazo: Reemplazo): Observable<Reemplazo> {
    const data: any = reemplazo;
    data.reemplazante_id = reemplazo.reemplazante.id;
    data.reemplazado_id = reemplazo.reemplazado.id;
    data.cargo_sueldo_reemplazo_id = reemplazo.cargo_sueldo_reemplazo.id;

    return this.http
      .post<Reemplazo>(`${environment.apiServer}/reemplazos`, data)
      .pipe(map(r => Reemplazo.create(r)));
  }

  public edit(reemplazo: Reemplazo): Observable<Reemplazo> {
    const data: any = reemplazo;
    data.reemplazante_id = reemplazo.reemplazante.id;
    data.reemplazado_id = reemplazo.reemplazado.id;
    data.cargo_sueldo_reemplazo_id = reemplazo.cargo_sueldo_reemplazo.id;

    return this.http
      .post<Reemplazo>(
        `${environment.apiServer}/reemplazos/${reemplazo.id}`,
        reemplazo
      )
      .pipe(map(r => Reemplazo.create(r)));
  }

  public delete(reemplazo: Reemplazo): Observable<Reemplazo> {
    return this.http.delete<Reemplazo>(
      `${environment.apiServer}/reemplazos/${reemplazo.id}`
    );
  }

  public close(reemplazo: Reemplazo): Observable<Reemplazo> {
    return this.http
      .get<Reemplazo>(
        `${environment.apiServer}/reemplazos/close/${reemplazo.id}`
      )
      .pipe(map(r => Reemplazo.create(r)));
  }

  public verify(reemplazo: Reemplazo): Observable<Reemplazo> {
    return this.http
      .get<Reemplazo>(
        `${environment.apiServer}/reemplazos/verify/${reemplazo.id}`
      )
      .pipe(map(r => Reemplazo.create(r)));
  }

  public PersonaReemplazo(id: number): Observable<Reemplazo[]> {
    return this.http.get<Reemplazo[]>(`${environment.apiServer}/reemplazos/persona/${id}`)
      .pipe(map(res => res.map(r => Reemplazo.create(r))));
  }
}
