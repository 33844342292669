import { Cargo } from './cargo';

export class ConvenioCargo {
  public id: number;
  public cargo_id: number;
  public convenio_id: number;
  public cargo: Cargo;
  public garantizado: number;

  public static create(data): ConvenioCargo {
    const convenioCargo = Object.assign(new ConvenioCargo, data);
    convenioCargo.cargo = Cargo.create(convenioCargo.cargo);

    convenioCargo.update();
    return convenioCargo;
  }

  public update() {
  }
}

export class Convenio {
  public id: number;
  public nombre: string;
  public periodo: string;
  public activo: boolean;
  public cargos: ConvenioCargo[];
  public isActive: boolean;

  public static create(data): Convenio {
    const convenio = Object.assign(new Convenio, data);

    if (convenio.cargos) {
      convenio.cargos = convenio.cargos.map(c => ConvenioCargo.create(c));
    }

    convenio.update();
    return convenio;
  }

  public update(): void {
    this.updateIsActive();
  }

  private updateIsActive() {
    this.isActive = this.activo === true;
  }
}
