import { Contrato } from './contrato';
import { CentroCosto } from './centro-costo';

export class Comentario {
  public id: number;
  public persona_id: number;
  public fecha: Date;
  public cuerpo: string;

  public static create(data): Comentario {
    const comentario = Object.assign(new Comentario, data);

    return comentario;
  }
}

export class Persona {
  public id: number;
  public orden: number;
  public rut: string;
  public nombre: string;
  public apellido_paterno: string;
  public apellido_materno: string;
  public contrato: Contrato;
  public ceco_oficial_id: CentroCosto;
  public jerarquia: number;
  public cargo: string;
  public tipo_cargo: string;
  public comentario: Comentario;

  public static create(data): Persona {
    const persona = Object.assign(new Persona, data);
    if (persona.contrato) { persona.contrato = Contrato.create(persona.contrato); }
    if (persona.comentario) { persona.comentario = Comentario.create(persona.comentario); }

    persona.update();
    return persona;
  }

  public update() {

  }
}
