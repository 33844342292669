import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Persona, Comentario } from '../models/persona';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PersonalService {
  constructor(private http: HttpClient) {}

  public getAll(): Observable<Persona[]> {
    return this.http
      .get<Persona[]>(`${environment.apiServer}/personal`)
      .pipe(map(res => res.map(p => Persona.create(p))));
  }

  public getContratados(): Observable<Persona[]> {
    return this.http
      .get<Persona[]>(`${environment.apiServer}/personal/contratados`)
      .pipe(map(res => res.map(p => Persona.create(p))));
  }

  public getConsultando(fecha: Date): Observable<Persona[]> {
    const dateFormatted = moment(fecha).format('YYYYMMDD');

    return this.http
      .get<Persona[]>(
        `${environment.apiServer}/personal/${dateFormatted}/consultando`
      )
      .pipe(map(res => res.map(p => Persona.create(p))));
  }

  public getPersonalByCargo(cargo: string): Observable<Persona[]> {
    return this.http
      .get<Persona[]>(`${environment.apiServer}/personal/${cargo}`)
      .pipe(map(res => res.map(p => Persona.create(p))));
  }

  public get(id: number): Observable<Persona> {
    return this.http
      .get<Persona>(`${environment.apiServer}/personal/${id}`)
      .pipe(map(p => Persona.create(p)));
  }

  public create(persona: Persona): Observable<Persona> {
    const data: any = persona;
    data.ceco_oficial_id = persona.ceco_oficial_id.id;

    return this.http
      .post<Persona>(`${environment.apiServer}/personal`, data)
      .pipe(map(p => Persona.create(p)));
  }

  public edit(persona: Persona): Observable<Persona> {
    const data: any = persona;
    data.ceco_oficial_id = persona.ceco_oficial_id.id;

    return this.http
      .post<Persona>(`${environment.apiServer}/personal/${persona.id}`, data)
      .pipe(map(p => Persona.create(p)));
  }

  public delete(persona: Persona): Observable<Persona> {
    return this.http.delete<Persona>(
      `${environment.apiServer}/personal/${persona.id}`
    );
  }

  public personalDisponible(): Observable<Persona[]> {
    return this.getContratados();
  }

  public getCargo(persona: Persona, fecha: Date): Observable<string> {
    return this.http.get<string>(
      `${environment.apiServer}/personal/${persona.id}/cargo/${fecha}`
    );
  }

  public saveComentario(data: Comentario): Observable<Comentario> {
    return this.http
    .post<Comentario>(
      `${environment.apiServer}/personal/${data.persona_id}/comentario`,
      data
    )
    .pipe(map(c => Comentario.create(c)));
  }
}
