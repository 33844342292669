import { Persona } from './persona';
import { NaveTurno } from './nave';
import { ConditionError } from './error';

export class Guardia {
  public id: number;
  public naveTurno: NaveTurno;
  public fecha: Date;
  public cargo: string;
  public falla: boolean;
  public persona: Persona;
  public comentario: string;
  public estado: string;
  public isClosed: boolean;
  public isVerified: boolean;
  public errors: ConditionError[];
  public errorsStr: string;

  public static create(data): Guardia {
    const guardia = Object.assign(new Guardia, data);

    if (guardia.errors) {
      guardia.errors = guardia.errors.map(e => ConditionError.create(e));
    }

    guardia.update();
    return guardia;
  }

  public update(): void {
    this.generateErrorString();
  }

  private generateErrorString() {
    this.errorsStr = '';

    for (const err of this.errors) {
      this.errorsStr += err.toString() + '\n';
    }
  }
}
