import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable } from '../../../../node_modules/rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private http: HttpClient) { }

  refreshToken(): Observable<any> {
    return this.http.get<any>(`${environment.apiServer}/refresh-token`)
      .pipe(
        tap(res => {
          const username = JSON.parse(sessionStorage.getItem('currentUser')).username;
          sessionStorage.setItem('currentUser', JSON.stringify({ username, token: res.token }));
        })
      );
  }

  login(username: string, password: string) {
    return this.http.post<any>(`${environment.apiServer}/authenticate`, { username: username, password: password })
      .pipe(
        tap((res: any) => {
          // login successful if there's a jwt token in the response
          if (res && res.token) {
            // store username and jwt token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem('currentUser', JSON.stringify({ username, token: res.token }));
          }
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('currentUser');
  }
}
