import { Persona } from './persona';
import { Cargo } from './cargo';
import { Convenio } from './convenio';
import * as moment from 'moment';

export class Contrato {
  public id: number;
  public persona: Persona;
  public cargo_sueldo: Cargo;
  public cargo_contrato: string;
  public funcion: string;
  public convenio: Convenio;
  public sueldo_base: number;
  public sueldo_garantizado: number;
  public tipo_contrato: string;
  public fecha_inicio: Date;
  public fecha_termino: Date;
  public fecha_desvinculacion_real: Date;
  public tipo_desvinculacion: string;
  public estado: string;
  public isClosable: boolean;
  public isActive: boolean;
  public isClosed: boolean;
  public isVerified: boolean;

  public static create(data): Contrato {
    const contrato = Object.assign(new Contrato, data);
    contrato.convenio = Convenio.create(contrato.convenio);

    contrato.update();
    return contrato;
  }

  public update(): void {
    this.calcIsClosable();
    this.calcIsActive();
  }

  private calcIsClosable(): void {
    this.isClosable = false;
    const now = new Date();
    const fecha_contrato = new Date(this.fecha_desvinculacion_real);
    const ahora = now.getDate() + (now.getMonth() * 10000) + (now.getFullYear() * 100000);
    const fecha_calc = fecha_contrato.getDate() + (fecha_contrato.getMonth() * 10000) + (fecha_contrato.getFullYear() * 100000) + 1;
    console.log('fecha hoy : ' + now);
    console.log('fecha Contrato : ' + fecha_contrato);
    console.log('ahora : ' + ahora);
    console.log('fecha Contrato calc : ' + fecha_calc);
    if (this.fecha_desvinculacion_real &&  ahora - fecha_calc > 0) {
      this.isClosable = true;
    }
  }

  private calcIsActive(): void {
    this.isActive = !this.isClosable;
  }
}
