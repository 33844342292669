import { Injectable } from '@angular/core';
import { Convenio } from '../models/convenio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConveniosService {

  constructor(private http: HttpClient) { }

  public getAll(): Observable<Convenio[]> {
    return this.http.get<Convenio[]>(`${environment.apiServer}/convenios`)
      .pipe(
        map(res => res.map(c => Convenio.create(c)))
      );
  }

  public get(id: number): Observable<Convenio> {
    return this.http.get<Convenio>(`${environment.apiServer}/convenios/${id}`)
      .pipe(
        map(c => Convenio.create(c))
      );
  }

  public create(convenio: Convenio): Observable<Convenio> {
    const data: any = convenio;

    for (const idx of Object.keys(convenio.cargos)) {
      data.cargos[idx].cargo_id = data.cargos[idx].cargo.id;
    }

    return this.http.post<Convenio>(`${environment.apiServer}/convenios`, convenio)
      .pipe(
        map(c => Convenio.create(c))
      );
  }

  public edit(convenio: Convenio): Observable<Convenio> {
    const data: any = convenio;

    for (const idx of Object.keys(convenio.cargos)) {
      data.cargos[idx].cargo_id = data.cargos[idx].cargo.id;
    }

    return this.http.post<Convenio>(`${environment.apiServer}/convenios/${convenio.id}`, convenio)
      .pipe(
        map(c => Convenio.create(c))
      );
  }

  public delete(convenio: Convenio): Observable<Convenio> {
    return this.http.delete<Convenio>(`${environment.apiServer}/convenios/${convenio.id}`);
  }

  public close(convenio: Convenio): Observable<Convenio> {
    return this.http.get<Convenio>(`${environment.apiServer}/convenios/close/${convenio.id}`)
      .pipe(
        map(c => Convenio.create(c))
      );
  }
}
