import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Contrato } from '../models/contrato';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContratosService {

  constructor(private http: HttpClient) { }

  public getAll(): Observable<Contrato[]> {
    return this.http.get<Contrato[]>(`${environment.apiServer}/contratos`)
      .pipe(
        map(res => res.map(c => Contrato.create(c)))
      );
  }

  public get(id: number): Observable<Contrato> {
    return this.http.get<Contrato>(`${environment.apiServer}/contratos/${id}`)
      .pipe(
        map(c => Contrato.create(c))
      );
  }

  public create(contrato: Contrato): Observable<Contrato> {
    const data: any = contrato;
    data.persona_id = contrato.persona.id;
    data.convenio_id = contrato.convenio ? contrato.convenio.id : null;
    data.cargo_sueldo_id = contrato.cargo_sueldo.id;

    return this.http.post<Contrato>(`${environment.apiServer}/contratos`, data)
      .pipe(
        map(c => Contrato.create(c))
      );
  }

  public edit(contrato: Contrato): Observable<Contrato> {
    const data: any = contrato;
    data.persona_id = contrato.persona.id;
    data.convenio_id = contrato.convenio ? contrato.convenio.id : null;
    data.cargo_sueldo_id = contrato.cargo_sueldo.id;

    return this.http.post<Contrato>(`${environment.apiServer}/contratos/${contrato.id}`, data)
      .pipe(
        map(c => Contrato.create(c))
      );
  }

  public delete(contrato: Contrato): Observable<Contrato> {
    return this.http.delete<Contrato>(`${environment.apiServer}/contratos/${contrato.id}`);
  }

  public close(contrato: Contrato): Observable<Contrato> {
    return this.http.get<Contrato>(`${environment.apiServer}/contratos/close/${contrato.id}`)
      .pipe(
        map(c => Contrato.create(c))
      );
  }

  public getPcontract(id: number): Observable<Contrato> {
    return this.http.get<Contrato>(`${environment.apiServer}/contratos/persona/${id}`)
      .pipe(
        map(c => Contrato.create(c))
      );
  }
}
