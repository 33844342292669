import { Persona } from './persona';
import { ConditionError } from './error';

export class Condicion {
  public id: number;
  public condicion: string;
  public persona: Persona;
  public fecha_inicio: Date;
  public fecha_termino: Date;
  public comentario: string;
  public estado: string;
  public isClosable: boolean;
  public errors: ConditionError[];
  public errorsStr: string;
  public isClosed: boolean;
  public isVerified: boolean;

  public static create(data): Condicion {
    const condicion = Object.assign(new Condicion, data);

    if (condicion.errors) {
      condicion.errors = condicion.errors.map(e => ConditionError.create(e));
    }

    condicion.update();
    return condicion;
  }

  public update() {
    this.calcIsClosable();
    this.generateErrorString();
  }

  private calcIsClosable() {
    this.isClosable = false;
    const now = new Date();
    if (this.fecha_termino && (new Date(this.fecha_termino)) < now) {
      this.isClosable = true;
    }
  }

  private generateErrorString() {
    this.errorsStr = '';

    for (const err of this.errors) {
      this.errorsStr += err.toString() + '\n';
    }
  }
}
