import { Injectable } from '@angular/core';
import { Condicion } from '../models/condicion';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CondicionesService {
  constructor(private http: HttpClient) {}

  public getAll(condicion: string): Observable<Condicion[]> {
    console.log(condicion
      .toLowerCase()
      .replace(/\s+/g, '-'));
    return this.http
      .get<Condicion[]>(
        `${environment.apiServer}/condiciones/${condicion
          .toLowerCase()
          .replace(/\s+/g, '-')}`
      )
      .pipe(map(res => res.map(c => Condicion.create(c))));
  }

  public get(id: number): Observable<Condicion> {
    return this.http
      .get<Condicion>(`${environment.apiServer}/condiciones/${id}`)
      .pipe(map(c => Condicion.create(c)));
  }

  public create(condicion: Condicion): Observable<Condicion> {
    const data: any = condicion;
    data.persona_id = condicion.persona.id;

    return this.http
      .post<Condicion>(`${environment.apiServer}/condiciones`, data)
      .pipe(map(c => Condicion.create(c)));
  }

  public edit(condicion: Condicion): Observable<Condicion> {
    const data: any = condicion;
    data.persona_id = condicion.persona.id;

    return this.http
      .post<Condicion>(
        `${environment.apiServer}/condiciones/${condicion.id}`,
        data
      )
      .pipe(map(c => Condicion.create(c)));
  }

  public delete(condicion: Condicion): Observable<Condicion> {
    return this.http.delete<Condicion>(
      `${environment.apiServer}/condiciones/${condicion.id}`
    );
  }

  public close(condicion: Condicion): Observable<Condicion> {
    return this.http
      .get<Condicion>(
        `${environment.apiServer}/condiciones/close/${condicion.id}`
      )
      .pipe(map(c => Condicion.create(c)));
  }

  public verify(condicion: Condicion): Observable<Condicion> {
    return this.http
      .get<Condicion>(
        `${environment.apiServer}/condiciones/verify/${condicion.id}`
      )
      .pipe(map(c => Condicion.create(c)));
  }
}
