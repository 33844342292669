import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Especie } from '../models/especie';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EspeciesService {

  constructor(private http: HttpClient) { }

  public getAll(): Observable<Especie[]> {
    return this.http.get<Especie[]>(`${environment.apiServer}/especies`);
  }

  public get(id: number): Observable<Especie> {
    return this.http.get<Especie>(`${environment.apiServer}/especies/${id}`);
  }

  public create(especie: Especie): Observable<Especie> {
    const data: any = especie;

    return this.http.post<Especie>(`${environment.apiServer}/especies`, data);
  }

  public edit(especie: Especie): Observable<Especie> {
    const data: any = especie;

    return this.http.post<Especie>(`${environment.apiServer}/especies/${especie.id}`, data);
  }

  public delete(especie: Especie): Observable<Especie> {
    return this.http.delete<Especie>(`${environment.apiServer}/especies/${especie.id}`);
  }
}
