import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Embarque } from '../models/embarque';
import { Nave} from '../models/nave';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmbarquesService {
  constructor(private http: HttpClient) {}
  var_nave: any;
  public getAll(): Observable<Embarque[]> {
    return this.http
      .get<Embarque[]>(`${environment.apiServer}/embarques`)
      .pipe(map(res => res.map(e => Embarque.create(e))));
  }

  public get(id: number): Observable<Embarque> {
    return this.http.get<Embarque>(`${environment.apiServer}/embarques/${id}`);
  }

  public create(embarque: Embarque): Observable<Embarque> {
    const data: any = embarque;
    data.nave_id = embarque.nave.id;
    console.log(data.nave_id);
    if (embarque.especie) {
      data.especie_id = embarque.especie.id;
    }

    const horaZarpe = embarque.hora_zarpe.split(':');
    data.fecha_zarpe = moment
      .utc(embarque.fecha_zarpe)
      .hour(parseInt(horaZarpe[0], 10))
      .minutes(parseInt(horaZarpe[1], 10));

    if (embarque.fecha_recalada && embarque.hora_recalada.length) {
      const horaRecalada = embarque.hora_recalada.split(':');
      data.fecha_recalada = moment
        .utc(embarque.fecha_recalada)
        .hour(parseInt(horaRecalada[0], 10))
        .minutes(parseInt(horaRecalada[1], 10));
    }

    for (const idx of Object.keys(embarque.tripulacion)) {
      data.tripulacion[idx].persona_id = data.tripulacion[idx].persona.id;
    }

    return this.http
      .post<Embarque>(`${environment.apiServer}/embarques`, data)
      .pipe(map(e => Embarque.create(e)));
  }

  public edit(embarque: Embarque): Observable<Embarque> {
    const data: any = embarque;
    data.nave_id = embarque.nave.id;

    if (embarque.especie) {
      data.especie_id = embarque.especie.id;
    }

    const horaZarpe = embarque.hora_zarpe.split(':');
    data.fecha_zarpe = moment
      .utc(embarque.fecha_zarpe)
      .hour(parseInt(horaZarpe[0], 10))
      .minutes(parseInt(horaZarpe[1], 10));

    if (embarque.fecha_recalada && embarque.hora_recalada.length) {
      const horaRecalada = embarque.hora_recalada.split(':');
      data.fecha_recalada = moment
        .utc(embarque.fecha_recalada)
        .hour(parseInt(horaRecalada[0], 10))
        .minutes(parseInt(horaRecalada[1], 10));
    }

    for (const idx of Object.keys(embarque.tripulacion)) {
      data.tripulacion[idx].persona_id = data.tripulacion[idx].persona.id;
    }

    return this.http
      .post<Embarque>(`${environment.apiServer}/embarques/${embarque.id}`, data)
      .pipe(map(e => Embarque.create(e)));
  }

  public delete(embarque: Embarque): Observable<Embarque> {
    return this.http.delete<Embarque>(
      `${environment.apiServer}/embarques/${embarque.id}`
    );
  }

  public close(embarque: Embarque): Observable<Embarque> {
    return this.http
      .get<Embarque>(`${environment.apiServer}/embarques/close/${embarque.id}`)
      .pipe(map(e => Embarque.create(e)));
  }

  public verify(embarque: Embarque): Observable<Embarque> {
    return this.http
      .get<Embarque>(`${environment.apiServer}/embarques/verify/${embarque.id}`)
      .pipe(map(e => Embarque.create(e)));
  }

  public getCargos(embarque: Embarque, date: string): Observable<any> {
    return this.http.get<any>(
      `${environment.apiServer}/embarques/${embarque.id}/cargos/${date}`
    );
  }
}
