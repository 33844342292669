export class Cargo {
  public id: number;
  public tipo_cargo: string;
  public nombre: string;
  public grupo_cargo: string;
  public jerarquia: number;

  public static create(data): Cargo {
    const cargo = Object.assign(new Cargo, data);

    return cargo;
  }
}
