import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Nave, NaveTurno, Guardia } from '../models';
import { map } from 'rxjs/operators';
import { Moment } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class GuardiasService {
  constructor(private http: HttpClient) {}

  public saveGuardias(date: Moment, data): Observable<Nave> {
    return this.http
      .post<Nave>(`${environment.apiServer}/guardias`, {
        fecha: date.toDate(),
        guardias: data
      })
      .pipe(map(res => Nave.create(res, date)));
  }

  public closeTurno(date: Moment, turnoId: number): Observable<NaveTurno> {
    return this.http
      .post<NaveTurno>(`${environment.apiServer}/guardias/close`, {
        fecha: date.toDate(),
        naveTurnoId: turnoId
      })
      .pipe(map(res => NaveTurno.create(res, date)));
  }

  public verifyTurno(date: Moment, turnoId: number): Observable<NaveTurno> {
    return this.http
      .post<NaveTurno>(`${environment.apiServer}/guardias/verify`, {
        fecha: date.toDate(),
        naveTurnoId: turnoId
      })
      .pipe(map(res => NaveTurno.create(res, date)));
  }

  public getAll(): Observable<Guardia[]> {
    return this.http.get<Guardia[]>(`${environment.apiServer}/guardias`);
  }

  public get(id: number): Observable<Guardia> {
    return this.http.get<Guardia>(`${environment.apiServer}/guardias/${id}`);
  }

  public create(guardia: Guardia): Observable<Guardia> {
    const data: any = guardia;
    data.persona_id = guardia.persona.id;

    return this.http.post<Guardia>(`${environment.apiServer}/guardias`, data);
  }

  public edit(guardia: Guardia): Observable<Guardia> {
    const data: any = guardia;
    data.persona_id = guardia.persona.id;

    return this.http.post<Guardia>(
      `${environment.apiServer}/guardias/${guardia.id}`,
      data
    );
  }

  public delete(guardia: Guardia): Observable<Guardia> {
    return this.http.delete<Guardia>(
      `${environment.apiServer}/guardias/${guardia.id}`
    );
  }

  public deleteById(id: number): Observable<Guardia> {
    return this.http.delete<Guardia>(`${environment.apiServer}/guardias/${id}`);
  }
}
