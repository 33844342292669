import { CentroCosto } from './centro-costo';
import { Guardia } from './guardia';
import { Moment } from 'moment';
import * as moment from 'moment';
import { ConditionError } from './error';

export class Nave {
  public id: number;
  public nombre: string;
  public isActive: boolean;
  public turnos: NaveTurno[];
  public ceco: CentroCosto;
  public dotacion_comercial: number;
  id_division: any;
  matricula: any;
  distintivo_llamada: any;
  manga: number;
  puntal: number;
  clase: string;
  Eslora: number;
  Velocidad: any;


  public static create(data, fecha: Moment | null = null): Nave {
    const nave = Object.assign(new Nave, data);
    if (nave.turnos) { nave.turnos = nave.turnos.map(nt => NaveTurno.create(nt, fecha)); }

    nave.update();
    return nave;
  }

  public update() {
    if (this.turnos) { this.turnos.forEach(nt => nt.update()); }
  }
}

export class NaveTurno {
  public id: number;
  public nombre: string;
  public inicio: string;
  public termino: string;
  public piloto: boolean;
  public motorista: boolean;
  public tripulante: boolean;
  public descargador: boolean;
  public patrondescarga: boolean;
  public motoristadescarga: boolean;
  public descanso: boolean;
  public orden: number;
  public guardias: Guardia[];
  public isClosed: boolean;
  public isVerified: boolean;
  public isClosable: boolean;
  public fecha: Moment;
  public errors: ConditionError[];
  public errorsStr: string;

  public static create(data, fecha: Moment | null = null): NaveTurno {
    const naveTurno = Object.assign(new NaveTurno, data);
    if (naveTurno.guardias) { naveTurno.guardias = naveTurno.guardias.map(g => Guardia.create(g)); }
    if (fecha) { naveTurno.fecha = fecha; }

    return naveTurno;
  }

  public update() {
    if ( this.guardias ) { this.guardias.forEach(g => g.update()); }

    this.calcIsClosed();
    this.calcIsVerified();
    this.calcIsClosable();
    this.calcErrors();
    this.generateErrorString();
  }

  private calcIsVerified(): void {
    if (this.guardias.length === 0) {
      this.isVerified = false;
    } else {
      this.isVerified = this.guardias[0].isVerified;
    }
  }

  private calcIsClosed(): void {
    if (this.guardias.length === 0) {
      this.isClosed = false;
    } else {
      this.isClosed = this.guardias[0].isClosed;
    }
  }

  private calcIsClosable(): void {
    this.isClosable = false;
    const horaInicio = parseInt(this.inicio.split(':')[0], 10);
    const minutoInicio = parseInt(this.inicio.split(':')[1], 10);
    this.fecha.hour(horaInicio).minutes(minutoInicio);
    if (this.fecha && this.fecha < moment()) {
      this.isClosable = true;
    }
  }

  private calcErrors(): void {
    if (this.guardias.length > 0) {
      this.errors = this.guardias.map(g => g.errors)
        .reduce((result, e) => result.concat(e), []);
    } else {
      this.errors = [];
    }
  }

  private generateErrorString(): void {
    this.errorsStr = '';

    for (const err of this.errors) {
      this.errorsStr += err.toString() + '\n';
    }
  }
}
