import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Accidente } from '../models/accidente';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccidentesService {
  constructor(private http: HttpClient) {}

  public getAll(): Observable<Accidente[]> {
    return this.http
      .get<Accidente[]>(`${environment.apiServer}/accidentes`)
      .pipe(map(res => res.map(a => Accidente.create(a))));
  }

  public get(id: number): Observable<Accidente> {
    return this.http
      .get<Accidente>(`${environment.apiServer}/accidentes/${id}`)
      .pipe(map(a => Accidente.create(a)));
  }

  public create(accidente: Accidente): Observable<Accidente> {
    const data: any = accidente;
    data.persona_id = accidente.persona.id;

    return this.http
      .post<Accidente>(`${environment.apiServer}/accidentes`, data)
      .pipe(map(a => Accidente.create(a)));
  }

  public edit(accidente: Accidente): Observable<Accidente> {
    const data: any = accidente;
    data.persona_id = accidente.persona.id;

    return this.http
      .post<Accidente>(
        `${environment.apiServer}/accidentes/${accidente.id}`,
        data
      )
      .pipe(map(a => Accidente.create(a)));
  }

  public delete(accidente: Accidente): Observable<Accidente> {
    return this.http.delete<Accidente>(
      `${environment.apiServer}/accidentes/${accidente.id}`
    );
  }

  public close(accidente: Accidente): Observable<Accidente> {
    return this.http
      .get<Accidente>(
        `${environment.apiServer}/accidentes/close/${accidente.id}`
      )
      .pipe(map(a => Accidente.create(a)));
  }

  public verify(accidente: Accidente): Observable<Accidente> {
    return this.http
      .get<Accidente>(
        `${environment.apiServer}/accidentes/verify/${accidente.id}`
      )
      .pipe(map(a => Accidente.create(a)));
  }
}
