export class Model {
  public fromJSON(json: any) {
    for (const propName in json) {
      if (this.hasOwnProperty(propName)) {
        this[propName] = json[propName];
      }
    }

    return this;
  }
}
