import { Injectable } from '@angular/core';
import { Observable, of as observableOf} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Nave } from '../models/nave';
import { environment } from '../../../environments/environment';
import { Persona } from '../models/persona';
import { Embarque} from '../models/embarque';
import { map, delay } from 'rxjs/operators';
import { Moment } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class NavesService {

  constructor(private http: HttpClient) { }

  public getAll(): Observable<Nave[]> {
    return this.http.get<Nave[]>(`${environment.apiServer}/naves`)
      .pipe(
        map(res => res.map(n => Nave.create(n)))
      );
  }

  public getEmbarcables(): Observable<Nave[]> {
    return this.http.get<Nave[]>(`${environment.apiServer}/naves/embarcables`)
      .pipe(
        map(res => res.map(n => Nave.create(n)))
      );
  }

  public getTripulacion(id: number): Observable<Persona[]> {
    return this.http.get<Persona[]>(`${environment.apiServer}/naves/${id}/tripulacion`);
  }

  public getUltimaTripulacion(id: number): Observable<Persona[]> {
    return this.http.get<Persona[]>(`${environment.apiServer}/naves/${id}/ultima-tripulacion`);
  }

  public getReemplazoTripulacion(id: number): Observable<any[]> {
    return this.http.get<Persona[]>(`${environment.apiServer}/naves/${id}/ultima-tripulacion`);
  }

  public getUltimoEmbarque(id: number): Observable<Embarque> {
    return this.http.get<Embarque>(`${environment.apiServer}/naves/${id}/ultimo-embarque`);
  }

  public getUltimosEmbarcados(id: number): Observable<any> {
    return this.http.get<any>(`${environment.apiServer}/embarques/${id}/ultimos-embarcados`);
  }

  public getTGMstatus(id: number): Observable<any> {
    return this.http.get<any>(`${environment.apiServer}/tgm/${id}/istgm`);
  }

  public get(id: number): Observable<Nave> {
    return this.http.get<Nave>(`${environment.apiServer}/naves/${id}`)
      .pipe(
        map(res => Nave.create(res))
      );
  }

  public create(nave: Nave): Observable<Nave> {
    const data: any = nave;

    return this.http.post<Nave>(`${environment.apiServer}/naves`, data)
      .pipe(
        map(res => Nave.create(res))
      );
  }

  public edit(nave: Nave): Observable<Nave> {
    const data: any = nave;

    return this.http.post<Nave>(`${environment.apiServer}/naves/${nave.id}`, data)
      .pipe(
        map(res => Nave.create(res))
      );
  }

  public delete(nave: Nave): Observable<Nave> {
    return this.http.delete<Nave>(`${environment.apiServer}/naves/${nave.id}`);
  }

  public getTurnos(fecha: Moment): Observable<Nave[]> {
    return this.http.get<Nave[]>(`${environment.apiServer}/naves/turnos/${fecha.format('YMMDD')}`)
      .pipe(
        map(res => res.map(n => Nave.create(n, fecha)))
      );
  }
}
