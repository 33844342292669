import { Persona } from './persona';
import { ConditionError } from './error';

export class TGM {
  public id: number;
  public persona: Persona;
  public fecha_inicio: Date;
  public fecha_termino: Date;
  public estado: string;
  public isClosable: boolean;
  public errors: ConditionError[] = [];
  public errorsStr: string = '';
  public isClosed: boolean;
  public isVerified: boolean;

  public static create(data): TGM {
    const tgm = Object.assign(new TGM, data);

    tgm.update();
    return tgm;
  }

  public update() {
    this.calcIsClosable();
  }

  private calcIsClosable() {
    this.isClosable = false;
    const now = new Date();
    if (this.fecha_termino && (new Date(this.fecha_termino)) < now) {
      this.isClosable = true;
    }
  }
}
