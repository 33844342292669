import { Persona } from './persona';
import { Cargo } from './cargo';
import { Convenio } from './convenio';
import { ConditionError } from './error';

export class Reemplazo {
  public id: number;
  public reemplazado: Persona;
  public reemplazante: Persona;
  public cargo_sueldo_reemplazo: Cargo;
  public convenido_reemplazo: Convenio;
  public fecha_inicio: Date;
  public fecha_termino: Date;
  public estado: string;
  public isClosable: boolean;
  public errors: ConditionError[];
  public errorsStr: string;
  public isClosed: boolean;
  public isVerified: boolean;

  public static create(data): Reemplazo {
    const reemplazo = Object.assign(new Reemplazo, data);
    if (reemplazo.reemplazado) { reemplazo.reemplazado = Persona.create(reemplazo.reemplazado); }
    if (reemplazo.reemplazante) { reemplazo.reemplazante = Persona.create(reemplazo.reemplazante); }
    if (reemplazo.errors) { reemplazo.errors = reemplazo.errors.map(e => ConditionError.create(e)); }

    reemplazo.update();
    return reemplazo;
  }

  public update() {
    this.calcIsClosable();
    this.generateErrorString();
  }

  private calcIsClosable() {
    this.isClosable = false;
    const now = new Date();
    if (this.fecha_termino && (new Date(this.fecha_termino)) < now) {
      this.isClosable = true;
    }
  }

  private generateErrorString() {
    this.errorsStr = '';

    for (const err of this.errors) {
      this.errorsStr += err.toString() + '\n';
    }
  }
}
