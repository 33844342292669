export * from './model';
export * from './accidente';
export * from './cargo';
export * from './centro-costo';
export * from './condicion';
export * from './contrato';
export * from './convenio';
export * from './embarque';
export * from './especie';
export * from './guardia';
export * from './nave';
export * from './persona';
export * from './reemplazo';
export * from './tgm';
export * from './user';
export * from './error';
