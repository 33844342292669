import { Persona } from './persona';
import { Nave } from './nave';
import { Especie } from './especie';
import { ConditionError } from './error';

export class Tripulante {
  public id: number;
  public persona: Persona;
  public redero: boolean;
  public buzo: boolean;
  public cocinero: boolean;
  public panguero: boolean;
  public cargo: string;

  public static create(data): Tripulante {
    const tripulante = Object.assign(new Tripulante, data);
    if (tripulante.persona) { tripulante.persona = Persona.create(tripulante.persona); }

    tripulante.update();
    return tripulante;
  }

  public update() {
    this.persona.update();
  }
}

export class Embarque {
  public id: number;
  public marea: string;
  public nave: Nave;
  public especie: Especie;
  public tipo_embarque: string;
  public fecha_zarpe: Date;
  public hora_zarpe: string;
  public fecha_recalada: Date;
  public hora_recalada: string;
  public comentario: string;
  public tripulacion: Tripulante[];
  public estado: string;
  public isClosable: boolean;
  public errors: ConditionError[];
  public errorsStr: string;
  public isClosed: boolean;
  public isVerified: boolean;

  public static create(data): Embarque {
    const embarque = Object.assign(new Embarque, data);
    if (embarque.tripulacion) { embarque.tripulacion = embarque.tripulacion.map(t => Tripulante.create(t)); }
    if (embarque.errors) { embarque.errors = embarque.errors.map(e => ConditionError.create(e)); }

    embarque.update();
    return embarque;
  }

  public update() {
    if (this.tripulacion) { this.tripulacion.forEach(t => t.update()); }

    this.calcIsClosable();
    this.generateErrorString();
  }

  private calcIsClosable() {
    this.isClosable = false;
    const now = new Date();
    if (this.fecha_recalada && (new Date(this.fecha_recalada)) < now) {
      this.isClosable = true;
    }
  }

  private generateErrorString() {
    this.errorsStr = '';

    for (const err of this.errors) {
      this.errorsStr += err.toString() + '\n';
    }
  }
}
