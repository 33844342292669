import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TGM } from '../models/tgm';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TgmsService {
  constructor(private http: HttpClient) {}

  public getAll(): Observable<TGM[]> {
    return this.http
      .get<TGM[]>(`${environment.apiServer}/tgm`)
      .pipe(map(res => res.map(t => TGM.create(t))));
  }

  public get(id: number): Observable<TGM> {
    return this.http
      .get<TGM>(`${environment.apiServer}/tgm/${id}`)
      .pipe(map(t => TGM.create(t)));
  }

  public create(tgm: TGM): Observable<TGM> {
    const data: any = tgm;
    data.persona_id = tgm.persona.id;

    return this.http
      .post<TGM>(`${environment.apiServer}/tgm`, data)
      .pipe(map(t => TGM.create(t)));
  }

  public edit(tgm: TGM): Observable<TGM> {
    const data: any = tgm;
    data.persona_id = tgm.persona.id;

    return this.http
      .post<TGM>(`${environment.apiServer}/tgm/${tgm.id}`, data)
      .pipe(map(t => TGM.create(t)));
  }

  public delete(tgm: TGM): Observable<TGM> {
    return this.http.delete<TGM>(`${environment.apiServer}/tgm/${tgm.id}`);
  }

  public close(tgm: TGM): Observable<TGM> {
    return this.http
      .get<TGM>(`${environment.apiServer}/tgm/close/${tgm.id}`)
      .pipe(map(t => TGM.create(t)));
  }

  public verify(tgm: TGM): Observable<TGM> {
    return this.http
      .get<TGM>(`${environment.apiServer}/tgm/verify/${tgm.id}`)
      .pipe(map(t => TGM.create(t)));
  }
}
